$mobileBreakpoint: 1264px;

@media screen and (max-width: $mobileBreakpoint) {
    html {
        .top-bar {

            img.logo {
                float: none !important;
            }

            .nav {
                display: none;
            }

            .navbar {
                display: inline-block !important;
                float: right;
            }
        }

        .customer-info {
            display: none;
        }

        .main-panel .header .insert-cont {
            position: relative;
            margin-left: 0;
            margin-top: 1rem;

            .control {
                max-width: 90%;
                min-width: 300px;
                width: auto;
            }
        }

        .showing-text {
            display: none;
        }
    }
}

@media (max-width: 991px) {
    html {
        h3 {
            font-size: 2rem;
            margin-top: 1.5rem;
        }

        .main-panel {
            .header {
                height: 18rem;

                &.has-insert {
                    height: 20.5rem;
                }
            }
        }

        .grid-search-panel {
            flex-direction: column;

            input,
            button,
            a {
                margin-right: 0;
                margin-bottom: 1rem;
                width: 100% !important;
            }
        }

        .main-panel .header .insert-cont {
            .select-container {
                width: 100%;
            }
         
            .control {
                max-width: none;
                width: 100%;
            }
        }

        .main-panel .header .add-cont {
            position: absolute;
            left: 1rem;
            top: 8rem;
        }

        .main-panel .header .container {
            padding: 0rem;
        }

        .main-panel>.content {
            margin: -4.25rem auto 0;
        }

        .content .container {
            padding-left: 0;
            padding-right: 0;

            >div {
                padding-left: 0;
                padding-right: 0;
            }
        }
    }
}

@media (max-width: 576px) {
    html {
        .table-footer {
            > nav:first-child {
                display: block !important;

                .pagination {
                    justify-content: center;
                    
                    .page-item .page-link {
                        font-size: 1rem;
                    }
                }
            }
        }
    }
}