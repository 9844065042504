html {
  .form-group.lookup-selection {
    width: 285px;
    margin-bottom: 0;
  }

  table tbody td.text-right {
    text-align: right;
  }
}

html {
  @media (max-width: 767.98px) {
    .min-mobile-width {
      min-width: 1000px !important;
    }
  }
}