html {
  .insert-cont .control {
    width: 345px;
  }

  .filter-buttons {
    padding-top: 0.75rem;
    padding-bottom: 1rem;
  }

  h1.trolley-header {
    margin-bottom: 0.5em;
    color: #4c4c4c;
    font-weight: 600;
    font-size: 18px;
    line-height: 1.4444444444444444;
  }

  form fieldset legend {
    margin-bottom: 0.5em;
    color: #4c4c4c;
    font-weight: 500;
    font-size: 16px;
    line-height: 1.4444444444444444;
  }

  .payment-info {
    color: $blue-dark;
    font-size: 13px;

    span.payment-info-header {
      font-weight: 600;
      color: $green-medium;
      text-transform: uppercase;
    }

    span.payment-info-text {
      display: inline-block;
    }
  }

  .accordion-button:not(.collapsed) {
    color: #000;
    background-color: #f3f3f3;
    box-shadow: inset 0 -1px 0 rgb(0 0 0 / 13%);
  }

}