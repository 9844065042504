html {
  .wrapper {
    position: relative;
    top: 0;
    height: 100vh;

    &.wrapper-full-page {
      min-height: 100vh;
      height: auto;
    }
  }

  .container {
    max-width: 1360px !important;
    margin: 0 auto;
  }

  .sidebar,
  .off-canvas-sidebar {
    background-color: $blue-dark;
    position: fixed;
    top: 0;
    height: 100%;
    bottom: 0;
    width: $sidebar-width;
    left: 0;
    z-index: 1030;

    .sidebar-wrapper {
      position: relative;
      height: calc(100vh - 5rem);
      overflow: auto;
      width: $sidebar-width;
      z-index: 4;
      padding: 0 1rem 4.5rem;

      .dropdown .dropdown-backdrop {
        display: none !important;
      }

      .navbar-form {
        border: none;
      }
    }

    li.log-out {
      position: fixed;
      left: 1rem;
      bottom: 1rem;
    }

    .nav {
      margin-top: 1.5rem;
      display: block;

      .caret {
        top: 14px;
        position: absolute;
        right: 10px;
      }

      .nav-link.active {
        opacity: 1;
        color: $white;
        background: #26274b;
        border-radius: 6px;

        &.parent {
          background: transparent;
        }
      }

      li {
        > a + div .nav li > a {
          margin-top: 7px;
        }

        > a {
          color: $white;
          display: table;
          text-decoration: none;
          position: relative;
          cursor: pointer;
          font-size: 0.875rem;
          font-weight: 600;
          letter-spacing: 0.02em;
          padding: 0.25rem 1rem;
          line-height: 30px;
          opacity: 0.9;
        }

        .nav > li > a {
          font-weight: 500;
          color: #a4a5cf;
          padding: 5px 0 5px 3rem;
        }

        &:hover:not(.active) > a,
        &:focus:not(.active) > a {
          opacity: 1;
        }

        .ionicon {
          vertical-align: text-top;
        }
      }

      a.nav-link {
        display: table;
        width: 100%;
      }

      p {
        display: table-cell;
        margin-bottom: 0;
        vertical-align: middle;
      }

      span.icon-cont {
        border-radius: 5px;
        display: table-cell;
        width: 1.25rem;
        height: 2rem;
        text-align: center;
        font-size: 1rem;
        padding-right: 0.75rem;

        svg {
          margin-top: -2px;
        }
      }

      .collapse,
      .collapsing {
        .nav {
          margin-top: 0;
        }
      }

      ul.sub-nav {
        margin-top: 0;
        padding-left: 0rem;
        list-style-type: none;
        -webkit-font-smoothing: antialiased;
      }
    }

    .sidebar-background {
      position: absolute;
      z-index: 1;
      height: 100%;
      width: 100%;
      display: block;
      top: 0;
      left: 0;
      background-size: cover;
      background-position: center center;

      &:after {
        position: absolute;
        z-index: 3;
        width: 100%;
        height: 100%;
        content: '';
        display: block;
        background: #ffffff;
        opacity: 1;
      }
    }

    .brand-content {
      padding: 0 1.5rem;
    }

    .logo {
      height: 5.375rem;
      position: relative;
      padding: 1.75rem 0 0;
      z-index: 4;
      border-bottom: 1px solid $blue-dark-line;

      a {
        display: block;

        img {
          height: 2rem;
        }
      }

      p {
        display: none;
        font-size: 0.675rem;
        margin: 0px 8px;
        letter-spacing: 0.1em;
        color: $white;
        text-align: left;
        line-height: 1.125rem;
        font-style: italic;
        font-family: 'Helvetica Neue', Helvetica, Arial, sans-serif;
      }
    }

    &[data-color='white'] {
      @include sidebar-color($white);
      @include sidebar-text-color($text-light);
    }

    &[data-color='black'] {
      @include sidebar-color($text-dark);
    }

    @include sidebar-active-color($text-highlight-color);
  }

  .visible-on-sidebar-regular {
    display: inline-block !important;
  }

  .visible-on- {
    display: none !important;
  }

  .off-canvas-sidebar {
    .nav {
      > li > a,
      > li > a:hover {
        color: $white;
      }

      > li > a:focus {
        background: rgba(200, 200, 200, 0.2);
      }
    }
  }

  .main-panel {
    position: relative;
    background-color: $main-panel-bg;

    @include transition(0.5s, cubic-bezier(0.685, 0.0473, 0.346, 1));

    > .content {
      padding: 0;
      margin: 0 2rem;
      min-height: calc(100vh - 123px);
      margin-top: -4.25rem;
      overflow-x: hidden;
    }

    > .navbar {
      margin-bottom: 0;
    }

    .header {
      padding: 1.5rem 2rem 2rem;
      background-color: $darkest-grey;
      background-color: #331c19;
      height: 24.25rem;
      position: relative;

      .top-bar {
        margin-right: -0.5rem;
      }

      .logo {
        width: 150px;
        float: left;
      }

      .container {
        padding: 0 2rem;
      }

      .add-cont {
        display: none;
        margin-top: 1rem;
        padding-left: 0.875rem;

        a {
          color: $white;
          text-decoration: none;
        }

        .create-icon {
          color: $green;
        }
      }

      .insert-cont {
        color: $white;
        margin-left: 2.5rem;
        margin-top: 1.75rem;

        label {
          margin-right: 1.5rem;
        }
      }

      .nav {
        .dropdown-item a {
          color: $black;
        }

        > .menu-item {
          padding: 0 0.5rem;
        }

        > .menu-item:last-child {
          background: $dark-grey-trans;
          border-radius: 999px;
        }

        > .menu-item > .nav-link {
          padding: 0.625rem 1.25rem;
        }

        > .menu-item > .nav-link.active {
          border-radius: 999px;
          background: $green-medium;
        }

        .nav-item.dropdown {
          margin-top: 2px;
        }

        .dropdown-menu {
          margin-left: -25px;
          border-radius: 12px;
          margin-top: 4px;
        }

        .dropdown-item {
          padding: 0;

          &:focus,
          &:active {
            background: transparent !important;
          }

          .nav-link {
            color: $cool-grey-table;
          }
        }

        .dropdown-toggle::after {
          margin-left: 0.5em;
          vertical-align: 0.175em;
        }

        a {
          color: $white;
          font-size: 1rem;
        }

        .icon-cont {
          display: none;
        }
      }
    }

    .customer-info {
      float: right;
      color: $white;
      text-align: right;
      margin-top: 0.25rem;
      font-size: 0.875rem;
      max-width: 45%;
      margin-right: -0.5rem;

      p {
        margin-bottom: 0.25rem;
      }

      .minor {
        color: #ffffffcc;
      }
    }

    .message-cont {
      position: absolute;
      left: 0;
      top: 0rem;
      text-align: center;
      width: 95%;
      animation: message-fadeout 0.5s forwards;
      animation-delay: 5s;

      .message {
        display: inline-block;
        padding: 0.25rem 1rem;
        background: #fff;
        border-radius: 0 0 5px 5px;
        color: black;
        border-width: 0px 3px 3px 3px;
        border-style: solid;
        font-size: 0.875rem;

        &.success {
          border-color: $green;
        }

        &.error {
          border-color: $red;
        }
      }
    }
  }

  @keyframes message-fadeout {
    from {
      opacity: 1;
    }

    to {
      opacity: 0;
    }
  }

  .perfect-scrollbar-on {
    .sidebar,
    .main-panel {
      height: 100%;
      max-height: 100%;
    }
  }

  // .panel-header {
  //   height: 260px;
  //   padding-top: 80px;
  //   padding-bottom: 45px;
  //   background: #141E30;  /* fallback for old browsers */
  //   background: -webkit-gradient(linear, left top, right top, from(#0c2646), color-stop(60%, #204065), to(#2a5788));
  //   background: linear-gradient(to right, #0c2646 0%, #204065 60%, #2a5788 100%);
  //   position: relative;
  //   overflow: hidden;

  //   .header{
  //     .title{
  //       color: $white;
  //     }
  //     .category{
  //       max-width: 600px;
  //       margin: 0 auto;
  //       font-size: 13px;

  //       a{
  //         color: $white;
  //       }
  //     }
  //   }
  // }

  // .panel-header-sm{
  //   height: 135px;
  // }

  // .panel-header-lg{
  //   height: 380px
  // }
}
