html {
    div.card {
        border-radius: 1.25rem;
        box-shadow: 0px 4px 24px 0px #344ABF1A;
        border-width: 0;
    }

    div.card-header {
        background: transparent;
        padding-left: 0;
        padding-right: 0;
        padding: 0 0 1rem 0 !important;
        border-width: 0;

        &.devices-filter {
            padding: 1.5rem 0 !important;
        }

        &.main {
            display: flex;
            justify-content: space-between;
            padding: 1.5rem 1.5rem 0 !important;
        }

        .action-insert {
            display: inline-block;
        }
    }

    div.card-header:not(.main):first-child {
        padding-bottom: 1rem !important;
    }

    div.card-body {
        padding: 1.5rem;
    }

    div.card-footer {
        background: $white;
        border-width: 0;
    }
}