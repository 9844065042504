$font-size-base: 16px;
$font-weight-normal: 500;
$font-color: #66615b !default;
$fill-font-color: rgba(255, 255, 255, 0.8);
$font-family-sans-serif: 'Source Sans Pro', "Helvetica", Arial, sans-serif;
$sans-serif-family: 'Source Sans Pro', 'Helvetica Neue', Arial, sans-serif;

$black: #000;
$white: #fff;
$cool-grey: #26284B;
$cool-grey-light: #F1F4F8;
$cool-grey-trans: #1D329F80;
$cool-grey-table: #394563;
$cool-grey-footer: #4A4F71;
$cool-grey-mid: #6B7295;
$cool-grey-100: #eff2f9;
$cool-grey-150: #e3e7f2;
$cool-grey-500: #6c7296;
$dark-grey: #263238;
$dark-grey-trans: #26323899;
$darkest-grey: #1c252a;
$cool-silver: #EFF2F9;
$cool-silver-darker: #E3E7F1;
$gray: #e2e9f1;
$silver: #646F8B;
$blue-dark: #151634;
$blue-dark-line: #26274B;
$blue-trans: #1D329F80;
$blue-light: #344ABF;
$blue-semi-lighter: #397DFF;
$blue-lighter: #547AFF;
$purple-100: #9babf9;
$purple-800: #273caf;
$purple-900: #2036a6;
$red: #fd0d3c;
$red-medium: #EE3737;
$green: #0dfd11; 
$green-medium: #26B43D;
$text-light: #fff;
$text-dark: #000;
$text-highlight-color: #fff; 
$main-panel-bg: #f4f3ef;

$transition-time: 800;
$transition-time-fast: 500;
$transition-ease: 'swing';

$navbar-margin-a: 15px 0px;
$navbar-margin-a-btn: 15px 0px;
$navbar-margin-a-btn-round: 16px 0px;
$navbar-padding-a-icons: 6px 15px;
$navbar-margin-a-icons: 6px 3px;
$navbar-padding-base: 0.625rem;
$navbar-margin-brand-icons: 12px auto;
$navbar-margin-btn: 15px 3px;
$white-navbar: rgba(#FFFFFF, .96);
$primary-navbar: rgba(#34ACDC, .98);
$info-navbar: rgba(#5BCAFF, .98);
$success-navbar: rgba(#4CD964, .98);
$warning-navbar: rgba(#FF9500, .98);
$danger-navbar: rgba(#FF4C40, .98);

$topbar-x:             topbar-x !default;
$topbar-back:          topbar-back !default;
$bottombar-x:          bottombar-x !default;
$bottombar-back:       bottombar-back !default;

$height-icon-sm: 32px;
$width-icon-sm: 32px;
$padding-icon-sm: 4px;
$border-radius-icon-sm: 7px;

$height-icon-message: 40px;
$width-icon-message: 40px;

$height-icon-message-sm: 20px;
$width-icon-message-sm: 20px;

$nc-font-path: '../fonts' !default;
$nc-font-size-base: 14px !default;
$nc-css-prefix: nc !default;
$nc-background-color: #eee !default;
$nc-li-width: (
    30em / 14) !default;
$nc-padding-width: (
    1em/3) !default;

// Sidebar variables
$sidebar-width: 270px;
$panel-width: calc(100% - $sidebar-width) !default;

// Placeholder text color
$input-color-placeholder: #999 !default;