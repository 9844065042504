html {
    table.table {

        th {
            font-size: 12px;
            color: $silver;
            text-transform: uppercase;

            .text-primary {
                color: $green-medium !important;
            }
        }

        td {
            color: $cool-grey-table;
            font-size: 0.875rem;

            &:first-child {
                color: $blue-dark;
            }
        }

        .sep {
            color: $gray;
            margin: 0 0.5rem;
        }

        .bg-primary {
            background: $cool-grey-trans !important;
        }

        .dx-g-bs4-resize-control-line-first {
            display: none;
        }

        &.with-action {
            .ionicon {
                color: $silver;
            }
        }

        .table-active {
            background-color: $cool-grey-light;
            color: $blue-dark;
            border-radius: 0px 0 12px 12px;
            box-shadow: none;

            .card {
                background: transparent;
                box-shadow: none;
            }

            table.table th {
                color: $green-medium;
            }
        }
    }

    .table-buttons {
        text-align: right;

        a {
            margin-left: 1rem;

            &:first-child {
                margin-left: 0;
            }
        }
    }

    thead,
    tbody,
    tfoot,
    tr,
    td,
    th {
        border-color: $gray;
    }

    .grid-search-panel {
        width: 100%;

        input {
            flex: 1;
        }

        input,
        button,
        a {
            margin-right: 1.5rem;

            &:last-child {
                margin-right: 0;
            }
        }
    }

    ul.pagination {

        .page-item {

            .page-link {
                font-size: 0.875rem;
                color: $blue-light;
                border: none;
                color: $cool-grey-footer;
                border-radius: 8px;

                &:focus {
                    box-shadow: none;
                }

                &:hover {
                    background-color: inherit;
                }
            }

            &:first-child {
                display: none;
            }

            &:last-child {
                display: none;
            }

            &.disabled .page-link {
                color: $silver;
            }

            &.active a {
                background: rgba(57, 125, 255, 0.1);
                color: $blue-semi-lighter;
            }
        }
    }

    .table-footer {

        nav:first-child {
            justify-content: center;
        }

        .float-right {
            float: none;
        }
    }
}