html {
    .header .navbar {
        padding: 0;
        z-index: 1;

        h3 {
            color: $white;
            margin-top: 0;
            margin-bottom: 0;
        }

        >button {
            background-color: $white;
        }

        .navbar-collapse {
            position: absolute;
            right: -1.5rem;
            top: 3.5rem;
            width: 100vw;
            background: $dark-grey;
            margin: 0;
            padding: 1rem;
        }

        .nav-link {
            color: $white;
            font-size: 1rem;
        }

        .icon-cont {
            display: none;
        }

        .menu-item p {
            color: white;
            font-size: 1.25rem;
            margin-bottom: 0.25rem;
        }

        .menu-item .active {
            font-weight: 600;
        }

        .sub-nav.nav {
            display: block;
        }
    }
}