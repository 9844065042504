html {
    .select-container {
        display: inline-block;

        .control {
            border-radius: 1.25rem;
            border-width: 0;
            box-shadow: none;
            height: 40px;
            // display: inline-block;

            &:hover {
                cursor: pointer;
            }
        }

        .single-value,
        .input {
            color: $cool-grey-table;
        }

        .value-container {
            padding-left: 1rem;
        }

        .indicator-container {
            >div {
                color: $cool-grey-table;
            }
        }

        .indicator-separator {
            display: none;
        }

        .menu {
            border-radius: 10px;
            max-height: 280px;
            z-index: 9999;
            color: $cool-grey;
            border-width: 0;
            box-shadow: 0px 3px 12px 0px #26284B1F;
            overflow: hidden;
        }

        .scrollbar-container {
            max-height: 280px;
        }

        .option {
            background-color: transparent;
            margin: 0.25rem 0.5rem 0.25rem 0.25rem;
            padding: 0.375rem 0.5rem 0.375rem 2rem;
            width: calc(100% - 0.75rem);
            border-radius: 6px;
        }

        .option:hover {
            background-color: $cool-silver;
        }

        .option.active {
            color: $cool-grey;
            background-image: url('../../images/select-check.png');
            background-position: 4px 8px;
            background-repeat: no-repeat;
            // background-color: transparent;
        }

        &.light {
            .control {
                background: $cool-silver;
            }

            .single-value,
            .input {
                color: $cool-grey;
            }

            .indicator-container>div {
                color: $cool-grey-mid;
            }
        }

        &.dark {
            .control {
                background: #D6DBE9;
            }

            .single-value,
            .input {
                color: #26284B;
            }

            .indicator-container>div {
                color: #6B7295;
            }
        }

        &.full-width {
            width: 100%;
        }
    }
}