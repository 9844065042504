:root {
    --toastify-icon-color-success: #14AE24;
    --toastify-icon-color-error: #D01A1A;
}

.Toastify__toast {
    font-weight: 500;
    letter-spacing: 0em;
    box-shadow: 0px 3px 12px 0px #26284B1F;
}

.Toastify__progress-bar--success {
    background: $green-medium;
}

.Toastify__progress-bar--error {
    background: $red-medium;
}
.Toastify__toast-container--top-center {
    top: 0.25rem !important;
}