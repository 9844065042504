html {
    .form-group {
        margin-bottom: 1.25rem;
    }

    .form-control {
        font-size: 0.875rem;
        border-radius: 1.25rem;
        border: 1px solid $cool-silver-darker;

        &:focus {
            box-shadow: none;
            border: 1px solid $cool-silver-darker;
        }
    }

    input[type="text"].form-control,
    input[type="date"].form-control,
    input[disabled].form-control,
    input[type="time"].form-control {
        height: 2.5rem;
    }

    label {
        color: $silver;
    }

    .insert-cont label {
        color: $white;
    }

    .checkbox {
        float: left;
        margin-right: 1rem;
    }

    form {
        h4 {
            margin-top: 1.5rem;
        }

        .space-right {
            margin-right: 1rem;
        }

        p.error {
            margin-top: 0.25rem;
            color: $red;
        }

        input[type="submit"] {
            color: $white;
            background-color: $blue-light;
            border-radius: 5px;
            border-width: 0;
            padding: 0.375rem 1rem;
            font-size: 1.125rem;
        }
    }

    .add-attachments {
        margin-bottom: 2rem;

        .attachment:first-child {
            padding-top: 0.75rem;
        }

        .attachment {
            padding-bottom: 0.75rem;
        }

        .attachment:not(:first-child) .row:first-child>div {
            border-top: 1px dashed silver;
            padding-top: 0.75rem
        }
    }
}