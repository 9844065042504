html {
    font-size: 16px;
}

html {
    body {
        font-family: Poppins;
        font-size: 14px;
        font-weight: 400;
    }

    h3 {
        font-size: 3.5rem;
        font-weight: 500;
        padding: 0 0 0 2.5rem;
        margin-top: 2.75rem;
    }

    h4 {
        display: inline-block;
        font-size: 1.125rem;
        line-height: 1.25rem;
        font-weight: 600;
    }
}