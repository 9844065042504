html {
  body {
    color: $black;
    font-size: $font-size-base;
    font-family: $sans-serif-family;
    font-weight: 500;
    -moz-osx-font-smoothing: grayscale;
    -webkit-font-smoothing: antialiased;

    a {
      text-decoration: none;
      color: $blue-lighter;

      &.danger {
        color: $red-medium;
      }
    }
  }

  .main {
    position: relative;
    background: $white;
  }

  .bg-primary {
    background-color: $green-medium !important;
  }

  .badge {
    font-size: 0.75rem;
    padding: 0.375rem 0.5rem;
  }

  .button-bar {
    display: block;
    position: relative;
    width: 22px;
    height: 1px;
    border-radius: 1px;
    background: $white;

    &+.button-bar {
      margin-top: 7px;
    }

    &:nth-child(2) {
      width: 17px;
    }
  }

  .caret {
    display: inline-block;
    width: 0;
    height: 0;
    margin-left: 2px;
    vertical-align: middle;
    border-top: 4px dashed;
    border-top: 4px solid\9;
    border-right: 4px solid transparent;
    border-left: 4px solid transparent;
  }

  .pull-left {
    float: left;
  }

  .pull-right {
    float: right;
  }

  .no-display {
    display: none
  }

  .main-overlay {
    position: fixed;
    right: 0;
    top: 0;
    width: 100vw;
    height: 100vh;
    background: #333;
    z-index: 5;

    img {
      display: block;
      margin: 48vh auto 0;
    }
  }

  .float-right {
    float: right;
  }

  .ck-editor__editable {
    min-height: 320px;
  }

  #manageRecipients li {
    padding-bottom: 0.25rem;
  }

  .ps .ps__rail-x:hover,
  .ps .ps__rail-y:hover,
  .ps .ps__rail-x:focus,
  .ps .ps__rail-y:focus,
  .ps .ps__rail-x.ps--clicking,
  .ps .ps__rail-y.ps--clicking {
    background: transparent !important;
  }

  .lookup-table-input {
    width: 200px;
  }

  .inline-space-r {
    margin-right: 1rem;
  }

  .inline-space-l {
    margin-left: 1rem;
  }

  .bg-success {
    background-color: $green-medium !important;
  }
}