body {
    div {
        .btn {
            border-radius: 999px;
            font-size: 0.875rem;
            line-height: 1rem;
            border-width: 0;
            padding: 0.75rem 1rem 0.75rem;
            height: 40px;

            .ionicon {
                margin-right: 0.75rem;
            }
        }

        .btn-primary {
            background-color: $green-medium !important;
            border-color: none;
        }

        .btn-secondary {
            background-color: #26323899 !important;
            color: $white;
        }

        .btn-light {
            background-color: $white !important;
            border: 1px solid $cool-silver-darker;
            color: $cool-grey;
        }

        .btn-margin {
            margin-right: 0.75rem;
        }
    }
}